<template>
  <div class="meal-wrap">
    <search-meal v-if="getSearchCondition"></search-meal>
    <div class="meal-list-wrap">
      <div class="bar-wrap">
        <el-button class="create-button" type="primary" @click="openDialog('mealSetting', { action: 'create' })">
          新增
        </el-button>
      </div>
      <el-table
        ref="listTable"
        :data="getMealList"
        header-cell-class-name="cus-table-header"
        row-class-name="cus-table-row"
        empty-text="尚無資料"
        class="table-basic"
        height="100%"
        @expand-change="handleRowClick"
      >
        <el-table-column type="expand">
          <template #default="props">
            <div style="padding: 0 48px 10px">
              <el-table
                :data="props.row.product"
                class="meal-product-table"
                header-cell-class-name="product-table-header"
              >
                <el-table-column label="內容" prop="product_name" />
                <el-table-column label="數量" prop="amount" width="100" />
                <el-table-column label="容量" width="100">
                  <template #default="scope">
                    <span>{{ mapOptionsLabel(scope.row.size) || '不指定' }}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="甜度" width="100">
                <template #default="scope">
                  <span>{{ mapOptionsLabel(scope.row.sweetness) || '不指定' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="溫度" width="100">
                <template #default="scope">
                  <span>{{ mapOptionsLabel(scope.row.temperature) || '不指定' }}</span>
                </template>
              </el-table-column> -->
              </el-table>
              <div class="product-content">
                <p>套餐售價：{{ getPriceText(props.row.prices) }}</p>
                <p v-if="props.row.remark" style="margin-top: 8px">備註：{{ props.row.remark }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="event_id" label="活動ID" width="130" />
        <el-table-column prop="name" label="名稱" />
        <el-table-column prop="available_shop_type" label="門市" width="220">
          <template #default="scope">
            <span v-if="scope.row.available_shop_type === 1">全門市</span>
            <span v-else-if="scope.row.available_shop_type === 2">
              {{ getAreaText(scope.row.available_shop_value_array) }}
            </span>
            <span
              v-else-if="scope.row.available_shop_type === 3"
              class="download-text"
              @click="downloadFile(scope.row.available_shop_file_url)"
            >
              指定門市（csv）
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_start_time" label="日期" width="160">
          <template #default="scope">
            <span v-if="scope.row.time_period_type === 1">不指定時間</span>
            <span v-if="scope.row.time_period_type === 2">
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.available_start_time) }}~
              <br />
              {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.available_end_time) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="available_status" label="狀態" width="100" />
        <el-table-column label="功能" fixed="right" align="center" width="130">
          <template #default="scope">
            <el-dropdown class="action">
              <span class="el-dropdown-link">
                操作
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="openDialog('mealSetting', { action: 'edit', init: scope.row, index: scope.$index })"
                  >
                    編輯
                  </el-dropdown-item>
                  <el-dropdown-item @click="handleDeleteMeal(scope.row)">刪除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-model:currentPage="currentPage"
        :page-size="getPageSize"
        layout="prev, pager, next, jumper"
        :total="getPageTotal"
        @click="handleCurrentChange"
      />
    </div>

    <!-- 彈窗 -->
    <component :is="dialogTemplate"></component>
  </div>
</template>
<script>
import { ref, getCurrentInstance, defineAsyncComponent, watch } from 'vue';
import { useDialog } from '@/utils/dialog';
import { useRoute, useRouter } from 'vue-router';
import { getAreaText, mapOptionsLabel } from '@/utils/define';
import { formatDateTime, getFormatDate } from '@/utils/datetime';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { downloadFile } from '@/utils/download';
export default {
  name: 'set-meal',
  components: {
    MealDialog: defineAsyncComponent(() => import('@/components/meal/MealDialog.vue')),
    SearchMeal: defineAsyncComponent(() => import('@/components/meal/SearchMeal.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();
    const route = useRoute();
    const router = useRouter();

    //取得資料
    const { getMealList, getPageSize, getPageTotal, getSearchCondition } = useGetters('meal', [
      'getMealList',
      'getPageSize',
      'getPageTotal',
      'getSearchCondition',
    ]);
    const SET_SEARCH_CONDITION = useMutations('meal', ['SET_SEARCH_CONDITION']);
    const { doGetMealList, doGetMealSummary } = useActions('meal', ['doGetMealList', 'doGetMealSummary']);

    //取得商品清單與容量對照表
    const doGetProductList = useActions('meal', ['doGetProductList']);
    const { doGetOptionList, doGetStoreList, doGetPriceSet } = useActions('common', [
      'doGetOptionList',
      'doGetStoreList',
      'doGetPriceSet',
    ]);
    const init = (() => {
      doGetProductList();
      doGetOptionList();
      doGetStoreList();
      doGetPriceSet();
    })();

    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: 1 };
    const searchDataInit = {
      date: [getFormatDate(), getFormatDate()],
      available_status: '',
      name: '',
      product_id: '',
      shop_id: '',
    };
    router.push({ query, replace: true }).then(() => {
      SET_SEARCH_CONDITION(searchDataInit);
      refreshList({ action: 'init' });
    });
    //價格文字
    const getPriceText = (arr) => {
      let text = '';
      arr.forEach((e, index) => {
        if (index === 0) {
          text += `${e.name} ${e.price}元 `;
        } else {
          text += `/ ${e.name} ${e.price}元`;
        }
      });
      return text;
    };

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    //更新list
    watch(
      () => route.query.page,
      () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          refreshList({ action: 'page' });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    const refreshList = async (data) => {
      if (data.action === 'create') {
        //若為新增套餐時，重設搜尋條件
        if (data.event.available_start_time) {
          let searchData = JSON.parse(JSON.stringify(searchDataInit));
          searchData.date = [
            data.event.available_start_time.slice(0, 10),
            data.event.available_start_time.slice(0, 10),
          ];
          SET_SEARCH_CONDITION(searchData);
        } else {
          SET_SEARCH_CONDITION(searchDataInit);
        }
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
      } else if (data.action === 'edit') {
        if (data.event.available_start_time) {
          let searchData = JSON.parse(JSON.stringify(searchDataInit));
          searchData.date = [
            data.event.available_start_time.slice(0, 10),
            data.event.available_start_time.slice(0, 10),
          ];
          SET_SEARCH_CONDITION(searchData);
        }
      }
      //排除分頁不打summary api
      if (data.action !== 'page') await doGetMealSummary(getSearchCondition.value);
      //summary會獲得新total，需計算頁碼，因刪除時會有減頁的情況
      if (currentPage.value > Math.ceil(getPageTotal.value / getPageSize.value)) {
        currentPage.value = Math.max(Math.ceil(getPageTotal.value / getPageSize.value), 1);
      }
      //頁碼確認後才打list api
      await doGetMealList({ search: getSearchCondition.value, start: (currentPage.value - 1) * getPageSize.value });
      closeDialog();
    };

    //刪除套餐
    const doDeleteMeal = useActions('meal', ['doDeleteMeal']);
    const handleDeleteMeal = (item) => {
      proxy
        .$confirm(`確定要刪除－${item.name}？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeleteMeal(item.event_id);
          refreshList({ action: 'delete' });
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        });
    };

    //展開收合row
    const handleRowClick = (row, expandedRows) => {
      if (expandedRows.includes(row)) {
        expandedRows.forEach((e) => {
          if (e !== row) listTable.value.toggleRowExpansion(e, false);
        });
      }
    };

    //離開清空vuex
    const RESET = useMutations('meal', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/product/meal')) RESET();
      }
    );
    return {
      downloadFile,
      getAreaText,
      mapOptionsLabel,
      formatDateTime,
      dialogTemplate,
      openDialog,
      getSearchCondition,
      getMealList,
      getPageSize,
      getPageTotal,
      init,
      listTable,
      currentPage,
      getPriceText,
      handleCurrentChange,
      refreshList,
      handleDeleteMeal,
      handleRowClick,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$searchbar-height: 70px;
$pagination-height: 50px;
.meal-wrap {
  height: 100%;
  @include basic-search-condition;
}
.meal-list-wrap {
  width: 100%;
  height: 100%;
  padding: $searchbar-height $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.bar-wrap {
  position: absolute;
  display: flex;
  top: calc($searchbar-height / 2);
  right: $padding-unit;
  transform: translateY(-50%);
}
.create-button {
  width: 110px;
  margin-right: 12px;
}
.table-basic {
  width: 100%;
  border-top: 1px solid $el-border-color-lighter;
}
.action {
  cursor: pointer;
  width: 110px;
  @include flex-center-center;
}
.el-dropdown-link {
  color: $color-primary;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
.product-content {
  padding: 15px 0 0 13px;
  p {
    color: $color-text-light;
  }
}
.meal-product-table {
  border: 1px solid $el-border-color-lighter !important;
  border-bottom: none !important;
  :deep(.product-table-header) {
    font-weight: 500;
    color: $color-text-light;
    background-color: $color-background !important;
  }
}
</style>
